import ApexCharts from "apexcharts"

import { Controller } from "@hotwired/stimulus"

export default class extends Controller  {
  static targets = ["chart"]

  static values = {
    newUsers: Array<Number>,
    total: Array<Number>,
    dates: Array<String>,
    title: String
  }

  declare chart: ApexCharts;
  declare chartTarget: HTMLElement;
  declare newUsersValue:  Number[];
  declare totalValue:  Number[];
  declare datesValue: String[];
  declare titleValue: String;

  connect() {
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  get chartOptions() {
    return {
      plotOptions: {
        bar: {
          columnWidth: '70%'
        }
      },
      stroke: {
        width: [0, 4]
      },
      chart: {
        offsetX: 10,
        offsetY: 15,
        toolbar: {
          offsetY: -5,
        },
        height: "350px",
        type: 'line',
      },
      colors: ['#66C7F4', '#99C2A2'],
      series: [{
        name: 'New Users',
        type: 'bar',
        data: this.newUsersValue,
      },
      {
        name: 'Total',
        type: 'line',
        data: this.totalValue,
      }
      ],
      yaxis: [{
        axisBorder: {
          show: true,
          color: '#373d3f',
          offsetY: -2,
        },
        labels: {
          style: {
            colors: '#373d3f',
          }
        },
        title: {
          text: "New Users",
          style: {
            color: '#373d3f',
          }
        },
      },
      {
        axisBorder: {
          show: true,
          color: '#373d3f',
          offsetX: -4,
          offsetY: -2,
        },
        labels: {
          style: {
            colors: '#373d3f',
          }
        },
        opposite: true,
        title: {
          text: "Total",
          style: {
            color: '#373d3f',
          }
        },
      },
      ],
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 4,
        position: 'front',
      },
      xaxis: {
        axisBorder: {
          show: true,
          color: '#373d3f',
          offsetX: -3,
          offsetY: -1,
        },
        tooltip: {
          enabled: false,
        },
        categories: this.datesValue,
        type: 'datetime',
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        }
      }
    }
  }
}
