import Choices from "choices.js"

const SELECTORS = [
  "select.js-choices",
  // AdminFormBuilder
  ".admin__form__field--select select",
  ".admin__form__field--collection_select select",
]

// TODO: use i18n for these values

const DEFAULT_OPTIONS = {
  allowHTML: true,
}

const applyChoicesJS = () => {
  SELECTORS.forEach((selector) => {
    const elements = document.querySelectorAll(selector)
    elements.forEach((element) => {
      // apply all elements which have ChoicesJS already applied
      if (element.getAttribute("data-choice") === "active") {
        return
      }
      const isMultiple = element.getAttribute("multiple") === "multiple"
      const options = {
        ...DEFAULT_OPTIONS,
        removeItemButton: isMultiple
      }
      // Let ChoicesJS render the placeholder for selects without empty option element
      if (element.getAttribute("placeholder")) {
        options["placeholderValue"] = element.getAttribute("placeholder")
      }
      new Choices(element, options)
    })
  })
}

export default {
  start: () => {
    document.addEventListener("DOMContentLoaded", applyChoicesJS)
    document.addEventListener("turbolinks:load", applyChoicesJS)
    applyChoicesJS()
  }
}
