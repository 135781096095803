import ApexCharts from "apexcharts"

import { Controller } from "@hotwired/stimulus"

export default class extends Controller  {
  static targets = ["chart"]

  static values = {
    activeUsers: Array<Number>,
    dates: Array<String>,
    title: String
  }

  declare chart: ApexCharts;
  declare chartTarget: HTMLElement;
  declare activeUsersValue: Number[];
  declare datesValue: String[];
  declare titleValue: String;

  connect() {
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  get chartOptions() {
    return {
      plotOptions: {
        bar: {
          columnWidth: '70%',
        }
      },
      stroke: {
        width: [0, 4]
      },
      chart: {
        offsetY: 15,
        height: "350px",
        type: 'bar',
      },
      colors: ['#66C7F4'],
      series: [{
        name: 'Active Users',
        data: this.activeUsersValue,
      }],
      yaxis: [{
        axisBorder: {
          show: true,
          color: '#373d3f',
          offsetY: -2,
        },
        title: {
          text: 'Daily ' + this.titleValue,
        },
      },
      ],
      xaxis: {
        axisBorder: {
          show: true,
          color: '#373d3f',
          offsetX: -3,
          offsetY: -1,
        },
        categories: this.datesValue,
        type: 'datetime',
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 4,
        position: 'front',
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        }
      }
    }
  }
}
