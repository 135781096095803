import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "date", "time" ]

  declare readonly dateTarget: HTMLElement
  declare readonly timeTarget: HTMLElement
  declare _interval: NodeJS.Timer

  connect() {
    this._interval = setInterval(this._tick, 1000)
    this._tick()
  }

  disconnect(): void {
    if (this._interval) {
      clearInterval(this._interval)
      delete this._interval
    }
  }

  _tick = () => {
    fetch("/server_time", {
      headers: {
        "Accept": "application/json"
      }
    })
    .then(resp => resp.json())
    .then(json => {
      const date = new Date(json)
      this.dateTarget.innerText = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      this.timeTarget.innerText = date.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    })
    .catch(error => {
      console.error(error)
      this.dateTarget.innerText = "Failed to fetch"
      this.timeTarget.innerText = ""
    })
  }
}
