import Rails from "@rails/ujs"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import React from "react"
import { createRoot } from "react-dom/client"

import Dummy from "./components/Dummy"

import Choices from "./choices"

import "normalize.css"
import "./stylesheets/application.scss"

const imageContext = (require as any).context("./images", true, /.(png|jpg|svg)$/)
imageContext.keys().forEach((key) => { imageContext(key) })

const Stimulus = window.Stimulus = Application.start()
const stimulusContext = require.context("./controllers/", true, /\.ts$/)
Stimulus.load(definitionsFromContext(stimulusContext))

console.log("First in pack: application")
Rails.start()
Choices.start()

const container = document.getElementById("root")
if (container) {
  const root = createRoot(container)

  document.addEventListener("DOMContentLoaded", () => {
    root.render(<Dummy />)
  })
}
