import React from "react"

import DummyImage from "../images/dummy.png"

const Dummy: React.FC = () => {
  return <>
    <h2 className="Dummy">Hello from React</h2>
    <img src={DummyImage} style={{width: 100, height: "auto"}} />
  </>
}

export default Dummy
