import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]
  static values = {
    doneLabel: String
  }
  static classes = [ "done" ]

  declare readonly sourceTarget: HTMLInputElement | HTMLSpanElement
  declare doneLabelValue: string
  declare readonly doneClass: string

  copy(evt: ActionEvent) {
    if (this.sourceTarget.getAttribute("data-clipboard-value")) {
      navigator.clipboard.writeText(this.sourceTarget.getAttribute("data-clipboard-value"))
    } else if (this.sourceTarget instanceof HTMLInputElement) {
      this.sourceTarget.select()
      navigator.clipboard.writeText(this.sourceTarget.value)
    } else {
      navigator.clipboard.writeText(this.sourceTarget.innerText)
    }
    const el = evt.currentTarget as Element
    el.classList.add(this.doneClass)
    el.innerHTML = this.doneLabelValue
  }
}
